import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography, Container, Box, Paper } from '@mui/material';
import { providers } from '../../components/providers/providers';
import { staff } from '../../components/providers/staff'
import PageTitle from '../../components/PageTitle';

const ProviderListPage = () => {

    const people = [...providers];
    const Speople = [...staff];

    return (
        <Container>
            <Grid>
                <PageTitle
                    title="Providers"
                    description="Welcome to Northland Psychological Services' Providers Page! You will find more information on each provider by clicking on their image!"
                />
            </Grid>
            <Grid container spacing={2} >
                {people && people.map(person => (
                    <Grid item xs={12} md={6} lg={4} xl={3} key={person.id}>
                        <Card
                            sx={{
                                backgroundColor: 'white',
                                transition: '0.3s',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                '&:hover': {
                                    '& img': {
                                        transform: 'scale(1.05)',
                                    },
                                },
                            }}
                        >
                            <CardActionArea
                                component={Link}
                                to={`/providers/${person.id}`}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={person.imageUrl}
                                    alt={person.name}
                                    sx={{
                                        width: '100%',
                                        height: '400px',
                                        objectFit: 'cover',
                                        transition: '0.3s',
                                    }}
                                />
                                <CardContent sx={{
                                    textAlign: 'center',
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Typography variant="h6" component="div">
                                        {person.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {/*Staff */}

            <Grid sx={{ p: 2, mt: 2 }} >
                <Paper elevation={18} sx={{ p: 2, mb: 2, textAlign: "center", borderRadius: "25px", backgroundColor: "#4f5a5e" }}>
                    <Box>
                        <Typography variant="h4" gutterBottom color="common.white">
                            Office Staff
                        </Typography>

                    </Box>
                </Paper>
            </Grid>
            <Grid container spacing={2}>
                {Speople && Speople.map(person => (
                    <Grid item xs={12} md={6} lg={4} xl={3} key={person.id}>
                        <Card
                            sx={{
                                backgroundColor: 'white',
                                transition: '0.3s',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                '&:hover': {
                                    '& img': {
                                        transform: 'scale(1.05)',
                                    },
                                },
                            }}
                        >
                            <CardActionArea
                                component={Link}
                                to={`/providers/${person.id}`}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={person.imageUrl}
                                    alt={person.name}
                                    sx={{
                                        width: '100%',
                                        height: '400px',
                                        objectFit: 'cover',
                                        transition: '0.3s',
                                    }}
                                />
                                <CardContent sx={{
                                    textAlign: 'center',
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Typography variant="h6" component="div">
                                        {person.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>




        </Container>
    );
};

export default ProviderListPage;