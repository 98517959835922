import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box } from "@mui/material";

function SlideshowFunc() {
  const items = [
    {
      
       src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow1.jpg"
    },
    {
      src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow.jpg"
    },
    {
      src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow2.jpg"
    },
    {
      src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow3.jpg"
    },
    {
      src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow4.jpg"
    },
    {
      src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow5.jpg"
    },
    {
      src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow6.jpg"
    },
    {
      src: "assets/images/northlandOfficePhotos/Slideshow/Slideshow7.jpg"
    }
  ];

  return (
    <Carousel animation="fade">
      {items.map((item, i) => (
        <Box key={i} sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px", textAlign: "center" }}>
          <img
            src={item.src}
            alt={`Slide ${i + 1}`}
            style={{ width: "100%", maxWidth: "100%", height: "auto" }}
          />
        </Box>
      ))}
    </Carousel>
  );
}

export default SlideshowFunc;
