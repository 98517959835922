import React from "react";
import { Typography, Button, Container, Grid, Paper, Box } from "@mui/material";
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SlideshowFunc from './Accordion';
import PageTitle from '../components/PageTitle';
import FAQS from '../components/homeFAQ';
//import { Link } from "react-router-dom";
const Home = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: 4 }}>
          <PageTitle
            title="Northland Psychological Services"
            description={
              <>
                <Typography variant="h5" gutterBottom>Mental Health Clinic in Hermantown, MN</Typography>
                <a
                  href="https://patientportal.advancedmd.com/137030/onlinescheduling/v2?streamlineOptions=2"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white'
                      }
                    }}
                  >
                    Schedule an Appointment
                  </Button>
                </a>
              </>
            }
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
              width: { xs: '90%', sm: '70%', md: '50%' }, // Responsive width
              maxWidth: '600px', // Maximum width
              margin: '0 auto', // Center align
            }}
            >
            <SlideshowFunc />
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 4 }}>
  <Paper elevation={18} sx={{ textAlign: "center", borderRadius: "25px" }}>
    <Typography variant="h5">
      About Us
    </Typography>
    <Typography variant="body1">
      Northland Psychological Services was founded in the spirit of collaboration. We are committed to creating an environment that enriches your life and the community in which we all live. We will work to provide comprehensive care not only within our office but in partnership with your other health care providers and support networks.
      Whether you're searching for help for yourself, your child, or your relationship, Northland Psychological's therapists work with you to understand the challenges you are facing while establishing a positive therapeutic relationship to help you create and achieve your personal goals. We hold sessions in our comfortable mental health clinic in Hermantown, MN or via teletherapy. Importantly, when you contact us, we make a point of listening carefully to your needs to match you with the best therapist.
      Our Mission is to provide comprehensive psychological services to improve the overall quality of life for individuals, couples, families and our community.
    </Typography>
    <Typography variant="body1">
      Contact us: 
      <a href="tel:218-729-6480" style={{ color: 'inherit', textDecoration: 'none', marginRight: '4px', marginLeft: '4px' }}>
        218-729-6480
      </a> 
      or 
      <br></br>
      <a href="mailto:reception@northlandpsychological.com" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '4px' }}>
        reception@northlandpsychological.com
      </a>
    </Typography>
    <Typography variant="body1">
    <a href="https://www.google.com/maps?s=web&lqi=Cihub3J0aGxhbmQgcHN5Y2hvbG9naWNhbCBzZXJ2aWNlcyBhZGRyZXNzIgJIAUjJ4-PO2IKAgAhaLhAAEAEQAhgAGAEYAiIgbm9ydGhsYW5kIHBzeWNob2xvZ2ljYWwgc2VydmljZXOSAQxwc3ljaG9sb2dpc3SqAUkQATIfEAEiG4J5L6M8erj2co1QpHM11-23vGS_Gsp_IYpvijIkEAIiIG5vcnRobGFuZCBwc3ljaG9sb2dpY2FsIHNlcnZpY2Vz4AEA&vet=12ahUKEwiZpPeA0OuHAxUhhIkEHRYtC28Q1YkKegQIHhAB..i&cs=0&um=1&ie=UTF-8&fb=1&gl=us&sa=X&geocode=KdlNNX3Vsq9SMW4a2QFD3ccG&daddr=4560+Norway+Pines+Pl,+Hermantown,+MN+55811" 
    style={{ color: 'inherit', textDecoration: 'none' }}>
    4560 Norway Pines, Hermantown MN, 55811
      </a>
     
    </Typography>
  </Paper>
</Grid>


        {/* Embed YouTube Video */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/qt14E27nM7c"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </Grid>

        {/* FAQ Section */}
        <Grid item xs={12}>
          <Grid xs={12}>
            <Box backgroundColor="#c4d0b8" p={2} textAlign="center" borderRadius="15px">
              <Typography variant="h5">
                Frequently Asked Questions
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
            <FAQS />
        </Grid>

        {/* APA and NHSC Logos */}
        <Grid container direction="row" justifyContent="center" alignItems="flex-end">
        
          <Grid item xs={6} sx={{ textAlign: "center" }}>
          <a target="_blank" rel="noreferrer" href="https://nhsc.hrsa.gov/" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '4px' }}>
            <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
              <img
                src="assets/images/pagePhotos/NHSC-logo.png"
                alt="NHSC Logo"
                style={{ width: "25%", maxWidth: "25%", height: "auto" }}
              />

            </Box>
            </a>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center" }}>
          <a target="_blank" rel="noreferrer" href="https://www.apa.org/" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '4px' }}>
            <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
              <img
                src="assets/images/pagePhotos/apaLogo.png"
                alt="APA Logo"
                style={{ width: "25%", maxWidth: "25%", height: "auto" }}
              />
            </Box>
            </a>
          </Grid>
        </Grid>

      </Grid>
    </Container>
  );
};

export default Home;