
import React from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  
  Paper,
  
} from "@mui/material";
import PageTitle from "../components/PageTitle";
import ContactForm from '../components/formFile';
const App = () => {
  
  return (
    
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageTitle title="Contact" description={
            <>
              Welcome to Northland Psychological Services' Contact and Employment! Our office is located at 4560 Norway Pines Pl Hermantown, MN 55811. Our email is <a href="mailto:reception@northlandpsychological.com">reception@northlandpsychological.com</a> and our office phone number is 218-729-6480. Scroll down to see how to contact us and view open office space and employment opportunities!
            </>
          } />
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ justifyContent: 'center', backgroundColor: '#4f5a5e', p: 3 }}>
            <Typography variant="h6" align='center' color='common.white' gutterBottom>
              Existing patients or new patients, schedule an appointment by clicking the button below
            </Typography>
            <Box display="flex" justifyContent="center">
              <a
                href="https://patientportal.advancedmd.com/137030/onlinescheduling/v2?streamlineOptions=2"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                >
                  Schedule an Appointment
                </Button>
              </a>
            </Box>
          </Paper>
        </Grid>

        <ContactForm/>
        {/* <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, borderRadius: '8px' }}>
            <Typography variant="h4" gutterBottom>Contact us</Typography>
            <Typography variant="body1" gutterBottom>
              Contact us through this form, or call the office directly at 218-729-6480
            </Typography>
            <form ref={form} onSubmit={sendEmail} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    name="first_name"
                    label="First name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    name="last_name"
                    label="Last name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    name="email"
                    label="Email address"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label="Your message"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid> */}

        <Grid item xs={12}>
  <Paper elevation={3} sx={{ p: 3, borderRadius: '8px', backgroundColor: '#4f5a5e' }}>
    <Box sx={{ position: 'relative', overflow: 'hidden', paddingTop: '56.25%' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2856.043468548895!2d-92.2361125!3d46.8375959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52afb2d57d354dd9%3A0x6c7dd4301d91a6e!2s4560+Norway+Pines+Pl%2C+Hermantown%2C+MN+55811!5e0!3m2!1sen!2sus!4v1631825502616!5m2!1sen!2sus"
        width="100%"
        height="100%"
        style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
        allowFullScreen=""
        loading="lazy"
        title="Map of Location"
      ></iframe>
    </Box>
  </Paper>
</Grid>

        <Grid item xs={12}>
          <Paper elevation={18} sx={{ p: 2, mb: 2, textAlign: "center", borderRadius: "25px" }}>
            <Typography variant="h6">
              Employment Opportunities
            </Typography>
            <Typography variant="body1">
              Here at Northland Psychological Services, we value motivated, caring and hardworking employees! We are looking for people to join the team.
              If you think you could be a good fit; send your resume to officemanager@northlandpsychological.com
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={18} sx={{ p: 2, mb: 2, textAlign: "center", borderRadius: "25px" }}>
            <Typography variant="h4">
              Available Office Space
            </Typography>
            <Typography variant="body1">
              Northland Psychological Services is located in a very spacious building with a variety of peaceful spaces catering to health and wellness needs!
              While a majority of our clinic is filled with personalized therapist offices, we also have many other spaces available to our clients.
              including a large and spacious yoga room, a calming Reiki room, and a serene outdoor office space!
              If you are looking to join our building with a health and wellness specialty, please reach out. We currently have 3 spaces available for rent and would love to incorporate more wellness specialties into our building.
              The dimensions and photos of each space can be found below. If interested, please contact us at reception@northlandpsychological.com or fill out a form on the Contact Page.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Container maxWidth="lg">
            <Grid container spacing={4} justifyContent="center" alignItems="flex-end">
              <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
                <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
                  <Paper elevation={3} sx={{ p: 2, borderRadius: "15px", backgroundColor: '#4f5a5e' }}>
                    <Typography variant="h6" color='common.white'  gutterBottom>Office 1: 14' by 12'</Typography>
                    <img
                      src="assets/images/northlandOfficePhotos/availableOffices/availOffice.jpg"
                      alt="Office 1"
                      style={{ width: "100%", height: "auto", borderRadius: "15px" }}
                    />
                  </Paper>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
                <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
                  <Paper elevation={3} sx={{ p: 2, borderRadius: "15px", backgroundColor: '#4f5a5e' }}>
                    <Typography variant="h6" color='common.white' gutterBottom>Office 2: 14' by 12'</Typography>
                    <img
                      src="assets/images/northlandOfficePhotos/availableOffices/availOffice2.jpg"
                      alt="Office 2"
                      style={{ width: "100%", height: "auto", borderRadius: "15px" }}
                    />
                  </Paper>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
                <Box id="employment" sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
                  <Paper elevation={3} sx={{ p: 2, borderRadius: "15px", backgroundColor: '#4f5a5e' }}>
                    <Typography variant="h6" color='common.white' gutterBottom >Office 3: 12' by 12'</Typography>
                    <img
                      src="assets/images/northlandOfficePhotos/availableOffices/availOffice3.jpg"
                      alt="Office 3"
                      style={{ width: "100%", height: "auto", borderRadius: "15px" }}
                    />
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default App;