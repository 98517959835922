import React from 'react';
import { Container, Grid, Typography, Button, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import FAQs from '../components/specialtiesFAQ.js';
import PageTitle from '../components/PageTitle';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const Services = () => {


  return (
    <Container maxWidth="lg" sx={{ overflowX: 'hidden', padding: 0, mt: 4 }}>
      <Grid container spacing={4}>
        {/* <Grid item xs={12} md={6}> */}
        <Grid item xs={12} >
          <PageTitle
            title="Services & Specialties"
            description={
              <>
                Welcome to Northland Psychological Services' specialties and services. Scroll through the many different services we offer for clients here!
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        mt: 2,
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': { backgroundColor: 'black', color: 'white' }
                      }}
                      href="#section2"
                    >
                      Skip to specialties
                    </Button>
                  </Grid>
                </Grid>
              </>
            }
          />
          <Grid container direction="row" justifyContent="center" alignItems="flex-end">
            <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
              <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
                <img
                  src="assets\images\northlandOfficePhotos\groupTherapy\groupTherapy3.jpg"
                  alt="Yoga"
                  style={{ width: "90%", maxWidth: "90", height: "auto", borderRadius: "25px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
              <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
                <img
                  src="assets\images\northlandOfficePhotos\singleCounseling\singleCounseling2.jpg"
                  alt="Yoga"
                  style={{ width: "90%", maxWidth: "90%", height: "auto", borderRadius: "25px" }}
                />
              </Box>
            </Grid>
          </Grid>


        </Grid>

        {/* </Grid> */}

      </Grid>

      <Box >
        <Paper elevation={18} sx={{ p: 2, mb: 6, textAlign: "center", borderRadius: "25px", backgroundColor: '#4f5a5e' }}>
          <Typography variant="h4" align='center' color='common.white'>
            Services
          </Typography>
        </Paper>
        <Grid container spacing={4}>
          {/* Group Counseling */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                height: { xs: 'auto', md: 215 },
                maxWidth: 500,
                margin: 'auto',
                backgroundColor: theme => theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                borderRadius: 2,
                boxShadow: 3,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center'
              }}
            >
              <ButtonBase sx={{ width: 128, height: 128, flexShrink: 0, mb: { xs: 2, sm: 0 } }}>
                <Img alt="Group Counseling" src="assets/images/northlandOfficePhotos/groupTherapy/groupTherapy2.jpg" />
              </ButtonBase>
              <Box sx={{ ml: { sm: 2 }, flexGrow: 1, overflow: 'hidden' }}>
                <Typography variant="subtitle1" component="div" noWrap>
                  Adult Individual Counseling
                </Typography>
                <Typography variant="body2" sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical'
                }}>
                  Adult Individual Counseling encompasses any difficulties an individual may face. This may include anxiety, trauma, depression, OCD, PTSD, relationship issues, among others.
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Relationship Counseling */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                height: { xs: 'auto', md: 215 },
                maxWidth: 500,
                margin: 'auto',
                backgroundColor: theme => theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                borderRadius: 2,
                boxShadow: 3,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center'
              }}
            >
              <ButtonBase sx={{ width: 128, height: 128, flexShrink: 0, mb: { xs: 2, sm: 0 } }}>
                <Img alt="Relationship Counseling" src="assets/images/northlandOfficePhotos/couplesTherapy/coulpes1.jpg" />
              </ButtonBase>
              <Box sx={{ ml: { sm: 2 }, flexGrow: 1, overflow: 'hidden' }}>
                <Typography variant="subtitle1" component="div" noWrap>
                  Couples Counseling
                </Typography>
                <Typography variant="body2" sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical'
                }}>
                  Couples counseling is open to any and all types of couples, including pre-marital, marriage, and LBGTQ+ counseling.
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Single Counseling */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                height: { xs: 'auto', md: 215 },
                maxWidth: 500,
                margin: 'auto',
                backgroundColor: theme => theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                borderRadius: 2,
                boxShadow: 3,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center'
              }}
            >
              <ButtonBase sx={{ width: 128, height: 128, flexShrink: 0, mb: { xs: 2, sm: 0 } }}>
                <Img alt="Single Counseling" src="assets/images/northlandOfficePhotos/singleCounseling/singleCounseling2.jpg" />
              </ButtonBase>
              <Box sx={{ ml: { sm: 2 }, flexGrow: 1, overflow: 'hidden' }}>
                <Typography variant="subtitle1" component="div" noWrap>
                  Group Therapy
                </Typography>
                <Typography variant="body2" sx={{
                  overflow: 'scroll',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical'
                }}>
                  Group sessions will begin in 2025, With a primary focus on Couples Courses, those who have experienced PTSD, and Addiction Therapy using the SMART Program. If you are interested, please use the Contact Page and send us a message including which program you are interested in to get on our list.
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Kid therapy */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 2,
                height: { xs: 'auto', md: 215 },
                maxWidth: 500,
                margin: 'auto',
                backgroundColor: theme => theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                borderRadius: 2,
                boxShadow: 3,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center'
              }}
            >
              <ButtonBase sx={{ width: 128, height: 128, flexShrink: 0, mb: { xs: 2, sm: 0 } }}>
                <Img alt="Supplements" src="assets\images\northlandOfficePhotos\kidTherapy\kidTherapy3.jpg" />
              </ButtonBase>
              <Box sx={{ ml: { sm: 2 }, flexGrow: 1, overflow: 'hidden' }}>
                <Typography variant="subtitle1" component="div" noWrap>
                  Child & Adolescent Therapy
                </Typography>
                <Typography variant="body2" sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical'
                }}>
                  Child and adolescent therapy services includes anxiety, depression, trauma, grief, behavior concerns and more.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ my: 4 }}>
        <Paper sx={{ p: 3, borderRadius: 2, boxShadow: 3 }}>
          <Typography variant="h5" gutterBottom>
            Services Overview
          </Typography>
          <Typography variant="body1">
            As Seen Above, here at Northland Psychological Services, we offer many different services. These include:
          </Typography>
          <ul>
            <li>Adult Individual Therapy</li>
            <li>Couples Therapy</li>
            <li>Child and Adolescent Therapy</li>
            <li>Family Therapy</li>
            <li>Group Therapy</li>
            <li>Supplements</li>
            <li>First Responder Trauma Therapy</li>

          </ul>
        </Paper>
      </Box>

      <Grid item xs={12} sx={{ textAlign: "center", py: 3 }}>
        <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
          <img
            src="assets/images/northlandOfficePhotos/reiki/reikipic4.jpg"
            alt="Someone getting reiki therapy"
            style={{ width: "100%", maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </Grid>

      {/* FAQ Section ******** */}
      <Grid item xs={12} p={2}>
        <Grid xs={6}>
          <Paper id="section2" elevation={18} sx={{ p: 2, mb: 3, textAlign: "center", borderRadius: "25px", backgroundColor: '#4f5a5e' }}>
            <Typography variant="h4" align='center' color='common.white'>
              Specialties
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        <FAQs />
      </Grid>
      






      <Grid container direction="row" justifyContent="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
          <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
            <img
              src="assets/images/northlandOfficePhotos/yoga/yoga1.jpg"
              alt="Yoga"
              style={{ width: "100%", maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "center", py: 3 }}>
          <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px" }}>
            <img
              src="assets\images\northlandOfficePhotos\brainSpotting\brainSpotting.jpg"
              alt="Yoga"
              style={{ width: "100%", maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Services;
