/**
 * Interface Provider {
    * id: string
    * name: string
    * imageUrl: string
    * description: string
    * specialization: string
    * specialtiesList: string[]
    * educationList: string[]
 *  }
 */

const heather_rose_carlson = {
    id: 'heather-rose-carlson',
    name: 'Dr. Heather Rose-Carlson, PsyD, LP',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Heather.png',
    description: 'As a native Duluthian, I grew up learning to embrace all that the Northland has to offer. With my husband and two fantastic children, we enjoy kayaking, gardening, fishing, berry picking, and a warm campfire with friends and family. Outside of the office, it has been an honor to work with our veterans and our community. Through Project New Hope, I am part of a team that provides weekend retreats to veterans and their families at no cost including a female veterans retreat to the BWCA every summer. I also provide Critical Incident Response when traumatic events occur in our community and volunteer for my local Fire Department. My dog, Kenai (5), often joins me in sessions.',
    specialization: 'I provide integrative therapy in a coaching manner. Most clients can identify what is going wrong or what is missing in their lives but need guidance on how to get back on track. People tend to be "stuck" trying what may have worked in the past and are frustrated that these same behaviors, expectations, and coping skills are not working in their current life circumstances.',
    specialtiesList: [
        'First Responders',
        'Veterans',
        'Couples (Gottman trained couples therapist)',
        'Issues: PTSD (PTSI), OCD, Panic, Depression, Anxiety',
        'Trauma-Focused Therapeutic Skills:',
        'Eye Movement Desensitization and Reprocessing (EMDR)',
        'Exposure Response Prevention (ERP)',
        'Imagery Rescripting and Reprocessing Therapy (IRRT)'
    ],
    educationList: [
        'Doctorate of Clinical Psychology (Psy.D.); Minnesota School of Professional Psychology at Argosy University (American Psychological Association accredited program)',
        'Bachelor of Arts, Psychology College of St. Scholastica, Summa Cum Laude'
    ]
}

const alex_ross = {
    id: 'alex-ross',
    name: 'Alex Ross MA, LPCC',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Alex.png',
    description: 'My approach to therapy is to use my knowledge to determine with clients and families what will be most helpful for their specific life circumstances and to meet them where they are in life. I hope to support resilience and provide the ability for individuals of all ages to address stressful life experiences and challenges. I am interested in creating a therapeutic experience that is a good fit for all, across life experiences and circumstances. When I\'m not working, I volunteer with the Arrowhead Critical Incident Stress Management Response team for First Responders, spend time with friends, family, and my dog, and am hoping to make it to every National Park.',
    specialization: 'I currently enjoy and have experience with working with individuals of varying ages in providing services to address traumatic experiences with children, adolescents, and adults as well as other life stressors that may result in depression, anxiety, grief, or other concerns. I am trained and nationally certified in evidence-based practices such as EMDR, CPT, and TF-CBT to work with individuals to determine what is the best fit.',
    specialtiesList: [],
    educationList: [
        'Masters in Counseling Psychology (Bethel University, St. Paul, Minnesota)',
        'Bachelor\'s in Forensic Science, Minor in Psychology (University of North Dakota)',
        'Licensed Professional Clinical Counselor (LPCC)'
    ]
}

const peggy_romero = {
    id: 'peggy-romero',
    name: 'Peggy Romero, MSW, LICSW',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Peggy.png',
    description: 'I come to Northland Psychological Services with 30-plus years of professional experience in the behavioral and physical health fields. Practicing mindfulness therapy, inner-child work, chakra balancing, guided movement meditation, and Reiki (energy work). I believe people are the experts of their own lives and it is not what is wrong with them, it is what they have experienced a variety of person-centered approaches to help people address their challenges to  and what they do with those experiences. I am their professional companion, using become "unstuck" and reconnect to self by offering tools, and resources and to hold space so they can move forward and live their lives to be their best selves. I have a quirky sense of humor with the belief that laughter is a universal language and a huge component of healing. When I am not practicing as a psychotherapist and energy worker, I enjoy planting flowers, communing with nature and trees, and sitting in Lake Superior. Above all else, I value and appreciate my precious family time and my beloved Yorkshire Terrier companion',
    specialization: 'I work with adults who live with chronic pain and illnesses, terminal illnesses, developmental trauma, PTSD, anxiety, depression, grief/ death, and loss. I am trained in Eye Movement and Desensitization and Reprocessing Therapy (EMDR), as well as Induced After Death Communication (IADC), to help heal grief associated with bereavement. Grief movement Guide, Nature-based Experiential Learning ( Ecotherapy), and Usui Holy Fire III Reiki Master, as well as Certified in Pet loss therapy.',
    specialtiesList: [],
    educationList: [
        'Masters in Clinical Social Work (MSW) (College of Saint Scholastica)',
        'Bachelor of Psychology (College of Saint Scholastica)',
        'Licensed and Independent Clinical Social Worker (LICSW)'
    ]
}

const rebecca_oachs = {
    id: 'rebecca-oachs',
    name: 'Rebecca Oachs, MSW, LICSW',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Rebecca.png',
    description: 'My focus as a therapist is educating on coping skills, providing insight on changing negative or anxious thought patterns, and giving you room for reflection and openness to considering/identifying alternative perspectives. Working together will include learning specific strategies for daily life. As you practice the techniques we discuss in my office, I will assist in examining thought patterns and using active problem-solving. My hope is that engaging in this work will give you active strategies to support your path to healthier living. Outside of the office, you can typically find me eating tacos or ice cream, playing with my cat, Tiger Lily, reading, napping, and spending time with my husband while walking on the Lakewalk or traveling. \n I am trained in providing Accelerated Resolution Therapy (ART), which facilitates processing of trauma. I am trained in Child Parent Psychotherapy (CPP), which facilitates processing stress for ages 3-5 within the parent child relationship, and provides me a unique perspective when working with clients who have experienced complex or developmental trauma. I also have specific training in providing support to parents and children of all ages, in addition to special interest in working with Obsessive Compulsive Disorder using Exposure Response Prevention (ERP).',
    specialization: 'I work to be an excellent critical listener, holding hope for growth and change while providing non-judgmental acceptance in our interactions. My work is strengths-based, that is, focusing on positives while balancing/considering current difficulties and desired changes. I feel my strengths lie in working with those who have experienced relational trauma and providing services for Obsessive Compulsive Disorder and a range of anxiety disorders. I am trained in using Trauma-Focused Cognitive Behavioral Therapy and Accelerated Resolution Therapy. I provide services for people struggling with mental health difficulties by teaching them to connect thoughts, feelings, and actions and challenging negative thought patterns. The past experience I have had in counseling young children and their caregivers has led to a unique understanding of how past experiences and relationships can impact symptoms in adults.',
    specialtiesList: [],
    educationList: [
        'Master\'s in Clinical Social Work (St. Catherine University/University of St. Thomas School of Social Work, St. Paul, MN) (MSW)',
        'Bachelor\'s in Social Work (Concordia College, Moorhead, MN)',
        'Licensed and Independent Clinical Social Worker (LICSW)',
        'Infant Parent Mental Health Post-Graduate Certificate Program (University of Massachusetts, Boston)'
    ]
}

const david_lindlbauer = {
    id: 'david-lindlbauer',
    name: 'David Lindlbauer, MSEd, LPCC',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\David.png',
    description: 'I believe we all come to therapy for different reasons, some must, and others choose to. In either situation, everyone is seeking to better themselves and is doing the best they can in their life. I seek to be a guide and partner to walk down the path of change with you. I believe everyone has the potential to be their best selves and achieve the goals they set, no matter what life has thrown their way. Outside of work, I like to spend my time with my family camping and hiking in Duluth or around the North Shore.',
    specialization: 'I currently have experience in working with adults who have experienced trauma in their lives. This has taught me a lot about meeting people where they are in life. You never know what someone has experienced in their life. With this mindset I utilize Brain Spotting, Trauma-Focused- Cognitive Behavioral Therapy TF-CBT), and Motivational Interviewing (MI).',
    specialtiesList: [],
    educationList: [
        'Master of Science in Education in Clinical Mental Health-University of Wisconsin-Superior (MSEd)',
        'Bachelor of Science Degree in Psychology-Northern Michigan University',
        'Licensed Professional Clinical Counselor (LPCC)'
    ]
}

const adam_huot = {
    id: 'adam-huot',
    name: 'Adam Huot, MSW, LGSW',
    imageUrl: '\\assets\\images\\updatedpics\\adamHeadshot.jpg',
    description: 'I have over 15 years of Law Enforcement experience as a police officer. I have worked as a patrol officer, investigator, field training officer, and as a member of the crisis negotiation team and peer support team. I obtained a Master\'s of Social Work from the College of St. Scholastica in 2020 because I wanted to serve the first responder community by providing counseling and trauma therapy. I have received training in the areas of trauma treatment, suicidality, critical incident response, and personal wellness. I have been teaching police officers and crisis response workers about post traumatic stress, resilience, and wellness for the last four years. I have also worked as a program staff member and as a therapist at a residential treatment center. My therapeutic focus is mainly on exposure to traumatic incidents and stress injury with a holistic wellness approach, focusing on sleep, exercise and movement, and creating positive connections. I attend CrossFit classes and yoga classes every week as part of my wellness routine, and obtained my CrossFit level 1 in July of 2022. I am a fantasy/sci-fi/action nerd, so anything Lord of the Rings, Game of Thrones, Harry Potter, or Marvel I could spend hours talking about. I have become interested in the brain science behind mental health, stress, trauma, and human connection over the last 5 years, and am always finding new ways to connect that into my practice as a therapist.',
    specialization: '',
    specialtiesList: [
        'First Responders',
        'Treatment of Trauma',
        'Stress Education and Resilience',
        'Critical Incident Stress',
        'Crisis Counseling',
        'Stress Injury',
        'Repeated exposure to trauma',
        'Anxiety',
        'Depression'
    ],
    educationList: [
        'B.A. in Criminology from UMD',
        'Master\'s of Social Work from College of Saint Scholastica',
        'LGSW (License in Graduate Social Work)'
    ]
}

const lidnzi_campbell = {
    id: 'lidnzi-campbell',
    name: 'Lindzi Campbell, Master\'s Student, Intern',
    imageUrl: '\\assets\\images\\updatedpics\\lindziHeadshot.jpg',
    description: 'I was born and raised in Duluth Minnesota and had the privilege of growing up on Park Point. This is where I built lifelong friendships and important connections that inspired me to dedicate my life\'s work to the helping professions.My family history is comprised of police officers and firefighters.I am a retired career firefighter and avid Jack of all trades, master of some.My hobbies and interests include home improvement, custom project fabrication, all things outdoors, socializing with friends, cooking, gardening, and basically anything I find my curious mind fixated with on any given day.My greatest passion lies in lending my skills and abilities to improving the safety and the wellbeing of my community.I received an Associate\'s Degree in Fire Technology and Administration from Lake Superior College and soon after became the first woman firefighter to be hired by Superior Fire Department in 1997. I returned to college in 2015 as an alternative(old, but still kickin\') student with the intention to pursue clinical counseling, specializing in trauma informed care with first responders.After six years of perseverance, I received my Bachelors of Applied Science in Psychology, graduating cum laude from the University of Wisconsin Superior.In 2023, I retired after 25 years of firefighting and went on to pursue a Master\'s Degree at the University of Minnesota- Duluth working towards becoming a Licensed Professional Clinical Counselor.',
    specialization: 'Medical and safety professionals consistently show up for people experiencing their very worst moments, putting them at a significantly higher risk for developing stress injuries and mental health disorders that arise from repeat exposure to stress and trauma. I believe that it is important to recognize that first responders face unique stressors that others do not. Having firsthand knowledge and experience of the cumulative effects of these exposures and the cultures in which they exist, I am uniquely suited to serving this population. I look forward to focusing my clinical practice on individuals who have worked on the front lines of emergency response.',
    specialtiesList: [],
    educationList: [
        'Associates Degree in Fire Technology and Administration from Lake Superior College',
        'B.A.S. in Psychology from University of Wisconsin Superior',
        'Currently pursuing: M.A. of LPCC from University of Minnesota-Duluth'
    ]
}
const christina_finlo = {
    id: 'christina-finlo',
    name: 'Christina Finlo, MA, LFMT, SEP',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Christina.png',
    description: 'One side of therapy may involve pursuing wisdom, guidance and support.  The other, involves pursuing healing into our deepest self.  Some concepts to explore are...  How do you make sense of and think about your world and self? How do you experience and manage your emotional world, what are your survival styles and patterns in relationships, distress and behavior? In what ways do you carry emotional pain and trauma on a body level, impacting you in known and unknown ways? I am trained as a Somatic Experiencing Practitioner to work with the release and resolution of trauma that can be trapped in ones physiology and nervous system. I have been in practice for over 12 years, including private practice, community health, and residential treatment. I have worked with all sorts of teens, couples, families and adult individuals. I have experience working with numerous issues involving mood, behavior, trauma, relationships, gender/sexuality, and self-concept.​',
    specialization: '',
    specialtiesList: ['Trauma', 'Shame', 'Toxic loved ones', 'Recovery from religion' ],
    educationList: [
        'BS Alternative Education and Art',
        'MA Adlerian Counseling and Psychotherapy; Adler Graduate School',
        
    ]
}

export const providers = [
    heather_rose_carlson,
    christina_finlo,
    alex_ross,    
    peggy_romero,
    david_lindlbauer,
    rebecca_oachs,
    adam_huot,
    lidnzi_campbell,
];

export const providersMap = providers.reduce((map, provider) => {
    map[provider.id] = provider;
    return map;
}, {});
