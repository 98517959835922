import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Card, CardContent, CardMedia, List, ListItem, ListItemText, Container, Button, Link, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { providersMap } from '../../components/providers/providers';
import { staffMap } from '../../components/providers/staff';

const ProviderPage = () => {
    const { id } = useParams();
    var provider = providersMap[id];

    if (!provider) {
        provider = staffMap[id];
    }

    return (
        <Container>
            <Button
                component={Link}
                href="/providers"
                startIcon={<ArrowBackIcon />}
                variant="contained"
                sx={{ mb: 2 }}
            >
                Back
            </Button>
            <Card>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CardMedia
                        component="img"
                        // sx={{
                        //     width: { xs: '100%', sm: '75%', md: '50%' },
                        //     maxHeight: '500px',
                        //     objectFit: 'cover',
                        // }}
                        sx={{
                            width: { xs: '100%', sm: '75%', md: '50%' },
                            maxHeight: '500px',
                            objectFit: 'cover',
                            border: '15px solid',
                            borderTop: '15px solid #c4d0b8',
                            borderBottom: '15px solid #c4d0b8',
                            borderImageSlice: '33%',
                            borderImageRepeat: 'round',
                        }}
                        image={provider.imageUrl}
                        alt={provider.name}
                    />
                </Box>
                <CardContent>
                    <Typography variant="h4" gutterBottom>{provider.name}</Typography>
                    <Typography variant="body1" color="textPrimary" gutterBottom>{provider.specialization}</Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>{provider.description}</Typography>

                    <Typography variant="h6" gutterBottom>{provider.specialtiesList.length > 0 ? "Specialties:" : ""}</Typography>
                    <List>
                        {provider.specialtiesList.map((specialty, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={specialty} />
                            </ListItem>
                        ))}
                    </List>

                    {provider.educationList && (
                        <>
                            <Typography variant="h6" gutterBottom>Education:</Typography>
                            <List>
                                {provider.educationList.map((education, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={education}
                                            primaryTypographyProps={{
                                                sx: {
                                                    '&::before': {
                                                        content: '"★ "',
                                                        color: '#c4d0b8',
                                                        marginRight: '8px'
                                                    }
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
};

export default ProviderPage;
